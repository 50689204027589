@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: #141821;
}

header{
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: #202736;
  min-height: 5vh;
  font-size: calc(10px + 2vmin);
  color: white;
}
.logo{
  padding: 5%;
  width: auto;
  display:inline-block;
}
a {
  color: #ff9900;
  /* text-decoration: none; */
}
::selection {
  background: #ff9900; /* WebKit/Blink Browsers */
}
.floating_bg{
  pointer-events: none;
  position: relative;
  height: 100vh;
  width: 100%;
}
img {
  border-radius: 50%;
}
.bigText{
  text-shadow:0.1px 0.1px 15px rgba(255, 255, 255, 0.26);
  font-size: calc(10px + 12vmin);
  font-weight: bold;
  color: #fff;
  padding: 15px;
}
.contactText{
  font-weight: bold;
  font-size: calc(4vmin);
  color: white;
  padding-left: 2%;
}

.infrontClass {
  z-index: 1;
}

.card-title {
  text-decoration: underline;
  font-size: 3vmin;
}

.card {
  box-shadow: -11px 10px 16px 1px rgba(0, 0, 0, 0.4);
  background: linear-gradient(180deg, #0080ff, #00b7ff);
  transition: all 0.2s ease;
  color: white;
  min-height: 21vmax;
}

.card:hover {
  cursor: pointer;
  transform: translatey(-15px);
}

.titleText {
  position: absolute;
  text-shadow: 0.1px 0.1px 15px rgba(255, 255, 255, 0.26);
  font-size: calc(10px + 15vmin);
  font-weight: bold;
  width: 100%;
  color: #fff;
  padding: 5%;
  align-content: center;
  justify-content: center;
  top: 30%;
  font-family: "Fira Code", monospace;
}
